/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(255, 230, 180, 255),
    PATH_HARD_COLOR: new cc.Color(145, 56, 188, 255),
    COLOR_BROWN: new cc.Color(94, 24, 89, 255),
    LETTER: new cc.Color(94, 24, 89, 255),
    COLOR_LIGHTBROWN: new cc.Color(200, 171, 124, 255),
    COLOR_VICTORY: new cc.Color(253, 217, 61, 255),
    COLOR_LIGHT_RED: new cc.Color(253, 60, 79, 255),
    HINT_ALARM_COLOR: new cc.Color(130, 130, 130, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    BROWN_TEXT_STROKE: {
        color: new cc.Color(255, 255, 255, 150),
        size: 2
    },

    BROWN_STROKE_2: {
        color: cleverapps.styles.COLORS.COLOR_BROWN,
        size: 2
    },

    WHITE_TEXT_BIG_SHADOW: {
        color: new cc.Color(0, 0, 0, 140),
        direction: cc.size(0, -6),
        blur: 1
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    CONGRATE_TEXT: {
        size: 100,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.TITLE_TEXT_SHADOW
    },

    EPISODE_TITLE_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    LETTERS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LETTER
    },

    DESCRIPTION_TEXT: {
        size: 23,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.WHITE,
        shadow: cleverapps.styles.DECORATORS.WHITE_TEXT_SHADOW
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    LevelId: {
        y: {
            dy: -6
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    background: {
        scale: 1.1
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 170,
    height: 82,

    text: {
        y: { align: "center" },
        x: { align: "center", dx: 40 }
    },

    icon: {
        x: { align: "left", dx: 5 }
    },

    position: [
        {
            x: { align: "left", dx: 30 },
            y: { align: "center", dy: -114 }
        },
        {
            x: { align: "left", dx: 30 },
            y: { align: "center", dy: -114 }
        },
        {
            x: { align: "left", dx: 30 },
            y: { align: "center", dy: -114 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.KeypadView, {
    controls: {
        submit: {
            y: { align: "bottom", dy: 20 }
        },
        cancel: {
            y: { align: "bottom", dy: 20 }
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LanguageButton, {
    height: 160
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        fitTo: {
            width: 450,
            height: 70
        },
        dx: 0,
        dy: 300,
        delay: 0.7
    },

    discountLabel: {
        dx: 320,
        dy: 320,
        delay: 1,
        duration: 0.75,
        fitTo: {
            width: 140
        }
    },

    animation: {
        "default": {
            idleDelay: 0,
            dx: -13,
            dy: -30
        },

        "starterPack0": {
            idleAnimation: "idle_800",
            introAnimation: "open_800"
        },

        "starterPack": {
            idleAnimation: "idle_2600",
            introAnimation: "open_2600"
        },

        "starterPack2": {
            idleAnimation: "idle_17000",
            introAnimation: "open_17000"
        }
    },

    button: {
        height: 110
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        canScale: false
    }
});

cleverapps.overrideStyles(cleverapps.styles.CharView, {
    topPaddingWithBg: 1
});

cleverapps.overrideStyles(cleverapps.styles.ProgressView, {
    force: {
        finger: {
            y: -5
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.WordGameScene, {
    minimalPosition: {
        x: { align: "center" },
        y: { align: "center", dy: -40 }
    }
});